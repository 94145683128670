import {dateFormatter, Typography} from '@hconnect/uikit'
import {Theme, makeStyles} from '@material-ui/core/styles'
import {InfoOutlined} from '@material-ui/icons'
import {Grid, Card, CardContent} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import {Address, ZipAndCityLabel} from '../../../../../Molecules/Address'
import {FieldValue} from '../../../../../Molecules/FieldDetails'
import {Features} from '../../../../../Organisms/Features'
import {isPaymentPending} from '../../components/InvoiceStatus'
import {Invoice} from '../../Invoice.types'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    boxSizing: 'border-box',
    borderLeft: '4px solid #EE8B00',
    marginBottom: theme.spacing(3)
  },
  cardContent: {
    color: '#435A70',
    padding: `${theme.spacing(3)} !important`,
    fontSize: 16
  },
  processingTitle: {
    textTransform: 'none'
  }
}))

interface InvoiceStatusType {
  invoice: Invoice
  t: TFunction
}

export const InvoiceStatus: React.FC<InvoiceStatusType> = ({invoice, t}) => {
  const classes = useStyles()
  const checkPaymentPending = isPaymentPending(invoice)

  return (
    <>
      <FieldValue
        dataTestId="invoice-status"
        label={t('invoice.status')}
        value={
          <Typography color={checkPaymentPending ? 'textPrimary' : 'primary'} customVariant="bold">
            {t(`invoice.statuses.${invoice.status}`)}
          </Typography>
        }
        gutter
      />
      {checkPaymentPending && (
        <Card
          variant="elevation"
          elevation={4}
          className={classes.card}
          data-test-id="payment-status-alert-card"
        >
          <CardContent className={classes.cardContent}>
            <Typography
              customColor="textDisabled"
              customVariant="captionBold"
              variant="subtitle2"
              color="textPrimary"
              className={classes.processingTitle}
            >
              {t('invoice.statuses.processing.title')}
            </Typography>
            <Typography variant="body2">{t('invoice.statuses.processing.description')}</Typography>
          </CardContent>
        </Card>
      )}
    </>
  )
}

interface InvoiceHeaderType {
  invoice: Invoice
  t: TFunction
  language: string
  isCustomerInvoiceNumber: boolean
  showState: boolean
  aside?: boolean
}

// eslint-disable-next-line complexity
export const InvoiceHeader: React.FC<InvoiceHeaderType> = ({
  invoice,
  t,
  language,
  isCustomerInvoiceNumber,
  showState,
  aside = false
}) => {
  if (!invoice) return null

  const invoiceNumber = (invoice: Invoice) =>
    isCustomerInvoiceNumber && invoice.customerInvoiceNumber
      ? invoice.customerInvoiceNumber
      : invoice.invoiceNumber
  const calcGrid = (isAside: boolean, inDetails: number, inAside = 12) =>
    isAside ? inAside : inDetails
  const calcOrder = (isAside: boolean, inDetails: number, inAside: number) =>
    isAside ? inAside : inDetails
  const addAllBreakpoints = (
    isAside: boolean,
    inDetails: number,
    inAside: number,
    calcFunc: typeof calcGrid | typeof calcOrder
  ) => ({
    xs: calcFunc(isAside, inDetails, inAside),
    sm: calcFunc(isAside, inDetails, inAside),
    lg: calcFunc(isAside, inDetails, inAside),
    md: calcFunc(isAside, inDetails, inAside),
    xl: calcFunc(isAside, inDetails, inAside)
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={calcGrid(aside, 4)}>
        <Grid container>
          <Grid item xs={12}>
            <InvoiceStatus invoice={invoice} t={t} />
          </Grid>
          <Grid item xs={aside ? 12 : 6} md={12} order={addAllBreakpoints(aside, 2, 2, calcOrder)}>
            <FieldValue
              dataTestId="invoice-issue-date"
              label={t(`invoiceDetail.invoiceDate.${invoice.type}`)}
              value={invoice.invoiceDate ? dateFormatter(invoice.invoiceDate, language) : undefined}
              gutter
            />
          </Grid>
          <Features name="InvoiceCreationDate">
            <Grid
              item
              xs={aside ? 12 : 6}
              md={12}
              order={addAllBreakpoints(aside, 3, 1, calcOrder)}
            >
              <FieldValue
                dataTestId="invoice-creation-date"
                label={t('invoiceDetail.creationDate')}
                value={
                  invoice.creationDate ? dateFormatter(invoice.creationDate, language) : undefined
                }
                gutter
              />
            </Grid>
          </Features>
          <Grid item xs={12} order={addAllBreakpoints(aside, 1, 3, calcOrder)}>
            <FieldValue
              dataTestId="invoice-number"
              label={t(`invoiceDetail.invoiceNumber.${invoice.type}`)}
              value={invoiceNumber(invoice)}
              gutter
            />
          </Grid>
          {invoice.isFinanceAccountingInvoice && invoice.customerInvoiceNumber && <Grid item xs={12} order={addAllBreakpoints(aside, 1, 3, calcOrder)}>
            <FieldValue
              dataTestId="invoice-external-reference"
              label={t('invoiceDetail.externalReference')}
              value={invoice.customerInvoiceNumber}
              gutter
            />
          </Grid>}
          <Features name="InvoicePaymentTerms">
            {invoice.status !== 'partiallyPaid' && invoice.paymentTermDescription ? (
              <Grid item xs={12} order={addAllBreakpoints(aside, 4, 4, calcOrder)}>
                <FieldValue
                  dataTestId="invoice-payment-terms"
                  label={t('invoiceDetail.paymentTerms')}
                  value={
                    <>
                      <InfoOutlined color="secondary" height={12} style={{marginBottom: -4}} />{' '}
                      {invoice.paymentTermDescription}
                    </>
                  }
                  gutter
                />
              </Grid>
            ) : null}
          </Features>
        </Grid>
      </Grid>
      <Grid item xs={12} md={calcGrid(aside, 8)}>
        <Grid container aria-label="Customer and billing address">
          {invoice.customer ? (
            <Grid item xs={12} md={calcGrid(aside, 6)} aria-label="Sold to address">
              <FieldValue label={t('invoiceDetail.soldTo')} dataTestId="invoice-soldTo" gutter>
                {invoice.customer.customerName}
                {invoice.customer.street && (
                  <Address
                    street={invoice.customer.street}
                    city={invoice.customer.city}
                    state={invoice.customer.state}
                    postalCode={invoice.customer.postalCode}
                    showState={showState}
                  />
                )}
                <ZipAndCityLabel variant="body2">
                  {invoice.customer.customerNumber} ({t('invoiceDetail.account')} #)
                </ZipAndCityLabel>
              </FieldValue>
            </Grid>
          ) : null}
          {invoice.billTo && invoice.billTo.street ? (
            <Grid item xs={12} md={aside ? 12 : 6} aria-label="Bill to address">
              <FieldValue label={t('invoiceDetail.billTo')} dataTestId="invoice-billTo" gutter>
                {invoice.billTo.billToName}
                {invoice.billTo.street && (
                  <Address
                    street={invoice.billTo.street}
                    city={invoice.billTo.city}
                    state={invoice.billTo.state}
                    postalCode={invoice.billTo.postalCode}
                    showState={showState}
                  />
                )}
                <ZipAndCityLabel variant="body2">
                  {invoice.billTo.billToNumber} ({t('invoiceDetail.account')} #)
                </ZipAndCityLabel>
              </FieldValue>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}
