import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import currencyFormatter from 'currency-formatter'
import {TFunction} from 'i18next'
import {groupBy, intersectionWith} from 'lodash'
import moment from 'moment'
import {Dispatch} from 'react'

import {sheetRequest} from '../../../../AsyncJobQueue/AsyncJobQueue.action'
import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestTypes
} from '../../../../AsyncJobQueue/AsyncJobQueue.types'
import {
  downloadDocument,
  Entity,
  EntityPage,
  getDocumentFileName
} from '../../../../Organisms/Documents'
import {DeliveriesWithLineItems, TransportVehicleDetails} from '../Delivery.types'
import {AccountParams} from '../hooks'
import {
  BreakdownLineItem,
  FinanceAnalytics,
  Invoice,
  LineItem,
  LineItemType,
  LineItemTypes
} from '../Invoice.types'
import {findInvoiceDocument, getCriteria} from '../Invoice.utils'

export const NoCustomerReferenceKey = '__NO_CUSTOMER_REFERENCE__'

export const getFormattedRate = (
  item: BreakdownLineItem,
  invoice: Invoice,
  language: string,
  formattedRateEnabled: boolean
): string => {
  if (
    !formattedRateEnabled &&
    (item.materialDescription?.split(' ').includes('Pump') || !item.unitPrice)
  ) {
    trackEvent('hubPumpRateHidden', {
      product: 'hub'
    })
    return '-'
  }
  return currencyFormatter.format(item.unitPrice!, {
    code: invoice.invoiceCurrency,
    locale: language
  })
}

export const formatNumber = (number: number, language: string) =>
  new Intl.NumberFormat(language, {maximumFractionDigits: 2}).format(number)

export const exportInvoice = (
  invoice: Invoice,
  accountParams: AccountParams | undefined,
  user: User | null,
  jobId: string | undefined,
  format: ApiDownloadRequestFormats,
  analytics: FinanceAnalytics,
  dispatch: Dispatch<any>
) => {
  if (!accountParams) return
  const {host} = (window as Window).location
  const url = `${RegExp(/localhost/).exec(host) ? 'http' : 'https'}://${host}/downloadRequests`
  dispatch(
    sheetRequest({
      jobId,
      criteria: getCriteria({
        accountParams,
        invoiceNumber: invoice.invoiceNumber,
        type: 'invoiceDetails'
      }),
      type: ApiDownloadRequestTypes.invoiceDeliveryExport,
      format: format,
      name: `invoice-deliveries-${invoice.invoiceNumber}.${
        ApiDownloadRequestFormats.xlsx ? 'xlsx' : 'csv'
      }`,
      shortName: `invoice-${invoice.invoiceNumber}`,
      fileType: ApiDownloadRequestFormats.xlsx ? 'Excel' : 'CSV',
      dateRangeInDays: '1',
      email: user?.eMail || '',
      sendEmail: true,
      fullName: user?.name || '',
      url,
      country: user?.country || '',
      clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      analytics: {
        jobId,
        numberOfDaysSelected: '1',
        initiationSource: 'hub'
      }
    })
  )
  trackEvent('hubExportStart', {
    product: 'hub',
    jobId,
    fileFormat: ApiDownloadRequestFormats.xlsx ? 'excel' : 'csv',
    entryPoint: 'invoice-details',
    ...analytics
  })
}

export const downloadInvoicePdf = (
  invoice: Invoice,
  jobId: string,
  analytics: FinanceAnalytics,
  t: TFunction,
  getFeature: (name: string) => boolean,
  dispatch: Dispatch<any>
) => {
  if (!invoice) return
  const invoicePdf = findInvoiceDocument(invoice)
  const invoiceNumber = getFeature('CustomerInvoiceNumber')
    ? invoice.customerInvoiceNumber
    : invoice.invoiceNumber
  if (invoicePdf) {
    const fileName = getDocumentFileName(EntityPage.invoices, Entity.invoice, invoicePdf.media, t, {
      entityNumber: invoiceNumber
    })
    const documentId = invoicePdf.href.split('/')[1]
    trackEvent('hubDownloadSingle', {
      product: 'hub',
      jobId: jobId,
      downloadedItem: 'invoiceDetail',
      linkToDownloadedItem: invoicePdf.href,
      entryPoint: 'Invoice Details -> Download PDF',
      downloadedItemPosition: 0,
      issuedDate: invoice.invoiceDate,
      downloadedItemBusinessLine: invoice.businessLine,
      ...analytics
    })
    dispatch(downloadDocument(jobId, documentId, fileName))
  }
}

export const getData = (invoice?: Invoice, deliveriesWithLineItems?: DeliveriesWithLineItems) => {
  if (!invoice || !deliveriesWithLineItems) return undefined

  const GROUPED_BY = 'materialNumber'
  let data: any = []
  data = groupBy(
    deliveriesWithLineItems,
    (lineItem: LineItem) => lineItem[GROUPED_BY] || NoCustomerReferenceKey
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data = Object.entries<any>(data).map(([GROUPED_BY, lineItems]) => {
    const itemNumbers = new Set(lineItems.map((lineItem) => lineItem.itemNumber))
    const materialItems = invoice.materialItems.reduce(
      (
        agg: (
          | BreakdownLineItem
          | {
              deliveryNumber: string
              customerDeliveryNumber: string
              shippingType: string
              modeOfTransport: string
              truckId: string
              truckName: string
              truckLicensePlate: string
              orderNumber: string
              transportVehicleDetails: TransportVehicleDetails
            }
        )[],
        curr: BreakdownLineItem
      ) => {
        if (itemNumbers.has(curr.relatedLineItem)) {
          const {
            links,
            deliveryNumber,
            customerDeliveryNumber,
            shippingType,
            modeOfTransport,
            truckId,
            truckName,
            truckLicensePlate,
            transportVehicleDetails,
            orderNumber
          } = lineItems.find((li) => curr.relatedLineItem === li.itemNumber)
          agg = [
            ...agg,
            {
              ...curr,
              links,
              deliveryNumber,
              customerDeliveryNumber,
              shippingType,
              modeOfTransport,
              truckId,
              truckName,
              truckLicensePlate,
              transportVehicleDetails,
              orderNumber
            }
          ]
        }
        return agg
      },
      []
    )

    const serviceItems = intersectionWith(
      invoice.serviceItems,
      lineItems,
      (breakdownItem: BreakdownLineItem, lineItem: any) =>
        lineItem.itemNumber === breakdownItem.relatedLineItem
    )
    const surchargeItems = invoice.surchargeItems.reduce(
      (
        agg: (
          | BreakdownLineItem
          | {
              deliveryNumber: string
              customerDeliveryNumber: string
              orderNumber: string
            }
        )[],
        curr: BreakdownLineItem
      ) => {
        if (itemNumbers.has(curr.relatedLineItem)) {
          const {links, deliveryNumber, customerDeliveryNumber, orderNumber} = lineItems.find(
            (li) => curr.relatedLineItem === li.itemNumber
          )
          agg = [
            ...agg,
            {
              ...curr,
              links: [...(curr.links || []), ...links],
              deliveryNumber,
              customerDeliveryNumber,
              orderNumber
            }
          ]
        }
        return agg
      },
      []
    )
    // earliest date
    const shippingDate = lineItems.reduce((agg, curr) => {
      if (!curr.shippingDate) {
        return null
      }
      // ignore timezones
      const arrivedDate = moment(curr.shippingDate).utc(false)
      if (!agg) {
        return arrivedDate
      }
      return arrivedDate < agg ? arrivedDate : agg
    }, null)

    const materials: any = new Set(
      lineItems.map((lineItem) => ({
        materialDescription: lineItem.materialDescription,
        isCarbonSavingProduct: lineItem.isCarbonSavingProduct
      }))
    )

    const name = materials.size > 0 ? [...materials.values()][0].materialDescription : ''

    const orderNumbers = new Set(lineItems.map((lineItem) => lineItem.orderNumber || '-'))

    const contracts = new Set(lineItems.map((lineItem) => lineItem.contractNumber))

    const customerReference = new Set(lineItems.map((lineItem) => lineItem.customerReference))

    const lineItemsTypes = new Set<LineItemTypes>(
      lineItems
        .filter(
          (lineItem) =>
            lineItem.lineItemTypes.includes(LineItemType.service) ||
            lineItem.lineItemTypes.includes(LineItemType.surcharge) ||
            lineItem.lineItemTypes.includes(LineItemType.serviceOrSurcharge)
        )
        .map((item) =>
          item.lineItemTypes.find((lineItemType) =>
            [
              LineItemType.service,
              LineItemType.surcharge,
              LineItemType.serviceOrSurcharge
            ].includes(lineItemType)
          )
        )
    )

    const quantity = lineItems.reduce((agg, curr) => {
      if (agg === null) {
        return null
      }
      if (agg === undefined) {
        return {
          loadQuantity: curr.quantity,
          loadQuantityUom: curr.quantityUom
        }
      }
      if (agg.loadQuantityUom === curr.quantityUom) {
        agg.loadQuantity += curr.quantity
        return agg
      }
      return null
    }, undefined)

    const netAmount = lineItems.reduce((agg, curr) => agg + curr.netAmount, 0)

    return {
      shippingDate,
      customerReference,
      materials,
      quantity,
      netAmount,
      orderNumbers,
      contracts,
      lineItemsTypes,
      lineItems,
      materialItems,
      serviceItems,
      surchargeItems,
      name
    }
  })

  data = data.filter((x) => x !== undefined)
  return data
}
