/* eslint-disable complexity */
import {User} from '@hconnect/apiclient'
import {Grid, Page, TitleNav, Typography, useTranslation} from '@hconnect/uikit'
import {Overlay} from '@hconnect/uikit/src/lib/Shell/Overlay'
import {Box, Divider, Paper, Slide} from '@mui/material'
import classNames from 'classnames'
import {push} from 'connected-react-router'
import queryString from 'query-string'
import React, {useEffect, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {v4 as uuidV4} from 'uuid'

import {ROUTE as FinanceRoute, InvoiceRoute} from '../..'
import {ApiDownloadRequestFormats} from '../../../../AsyncJobQueue/AsyncJobQueue.types'
import {trackEvent} from '../../../../common/analytics'
import {PageNames} from '../../../../common/constants'
import {CustomHeader} from '../../../../Molecules/CustomHeader'
import {CustomSkeleton} from '../../../../Molecules/CustomSkeleton'
import ExportDropdown from '../../../../Molecules/ExportDropdown'
import {C60Lead} from '../../../../Molecules/LeadBanners'
import {withRouteRequiringPermission} from '../../../../Molecules/PermissionBoundary'
import {
  ResponsiveHeight,
  useResponsiveBreakpoints,
  useResponsiveGridStyles
} from '../../../../Molecules/Responsive.utils'
import {CustomerStateType, selectCustomers} from '../../../../Organisms/Customers'
import {Features, useFeaturesState} from '../../../../Organisms/Features'
import {selectInvoice} from '../../../../Organisms/Invoices'
import {PermissionTypes} from '../../../../Permissions'
import {AppState} from '../../../../Root.store'
import {useAccountParams, useDeliveriesByInvoiceId, useSurchargeDeliveries} from '../hooks'
import {useInvoice} from '../hooks/useInvoice'
import {Invoice, InvoiceType} from '../Invoice.types'
import {findInvoiceDocument, mergeDeliveriesWithLineItems} from '../Invoice.utils'

import {InvoiceHeader} from './components'
import {CostSummary} from './components/CostSummary'
import {InvoiceDeliveriesItems} from './components/InvoiceDeliveriesItems'
import {InvoiceItems} from './components/InvoiceItems'
import {InvoiceItemsByMaterialList} from './components/InvoiceItemsByMaterialList'
import {downloadInvoicePdf, exportInvoice, getData} from './InvoiceDetails.utils'
import InvoiceItemsTable from './InvoiceItemsTable'
import Content from '../../../../Molecules/Content'

const INVOICE_LOOKUP_URL_PARAM = 'lookup'

interface InvoiceDetailProps {
  invoiceId: string
  invoice: Invoice
  goBack: () => void
}
const InvoiceDetail: React.FC<InvoiceDetailProps> = ({invoiceId, invoice, goBack}) => {
  const {customerId, payerId, customInvoiceNumber} = queryString.parse(window.location.search)
  const accountParams = useAccountParams(true, payerId as string, customerId as string)
  const resolvedInvoiceId = invoiceId.startsWith(INVOICE_LOOKUP_URL_PARAM)
    ? invoiceId + window.location.search
    : invoiceId
  const dispatch = useDispatch()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {classes: responsiveClasses} = useResponsiveGridStyles()
  const {screenSize, smallScreen, largeScreen} = useResponsiveBreakpoints()
  const {getFeature} = useFeaturesState()

  const {
    data: invoiceData,
    error: invoiceError,
    isFetching: invoiceFetching
  } = useInvoice({
    accountParams,
    invoiceId: resolvedInvoiceId,
    invoice
  })

  const totalGrossValue = (invoiceData && invoiceData.invoice.invoiceGrossValue) || 0
  const {
    data: deliveryData,
    error: deliveriesError,
    isFetching: deliveriesFetching
  } = useDeliveriesByInvoiceId({
    accountParams,
    invoiceId: invoiceId.startsWith(INVOICE_LOOKUP_URL_PARAM) ? invoiceData?.invoice?.invoiceId ?? '' : invoiceId,
    customInvoiceNumber: customInvoiceNumber as string,
    invoice: invoiceData?.invoice
  })
  const {deliveries} = deliveryData || {}
  const {
    data: surchargeDeliveries,
    isFetching: surchargeDeliveriesFetching,
    isError: surchargeDeliveriesError
  } = useSurchargeDeliveries({
    surcharges: invoiceData?.invoice?.surchargeItems
  })

  const customersState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {selectedCustomer, isFetching: isCustomersFetching} = customersState
  const analytics = {
    userId: user?.user_id,
    userMail: user?.eMail,
    userCountry: user?.country,
    userRoles: user?.hasRoles,
    userIsInternal: user?.isInternal,
    customerId: selectedCustomer?.customerId,
    customerName: selectedCustomer?.customerName
  }
  const [selectedRow, setSelectedRow] = useState<any>(undefined)

  const [tableData, setTableData] = useState<any>(undefined)

  useEffect(() => {
    if (deliveries && invoiceData?.invoice) {
      const deliveriesWithLineItems = mergeDeliveriesWithLineItems(deliveries, invoiceData.invoice)
      const data = getData(invoiceData?.invoice, deliveriesWithLineItems)
      setTableData(data)
    }
  }, [deliveries, invoiceData?.invoice])

  const [showSlide, setShowSlide] = useState<boolean>(false)
  useEffect(() => {
    if (selectedRow) {
      setShowSlide(true)
    }
  }, [selectedRow])

  const [jobId, setJobId] = React.useState<string | undefined>(undefined)
  const handleExportClick = () => {
    const jobIdTemp = uuidV4()
    setJobId(jobIdTemp)
    trackEvent('hubExportClick', {
      product: 'hub',
      jobId: jobIdTemp,
      entryPoint: 'invoice',
      userId: user?.user_id || '',
      userCountry: user?.country,
      customerId: accountParams?.customerId,
      payerId: accountParams?.payerId,
      customerName: selectedCustomer?.customerName,
      lookUpQuery: invoiceData?.invoice?.invoiceNumber,
      lookUpCategory: 'invoiceNumber'
    })
  }
  const handleExportClose = () => setJobId(undefined)
  const isCustomerInvoiceNumber = getFeature('CustomerInvoiceNumber')
  const canDownloadPdf =
    invoiceData?.invoice && findInvoiceDocument(invoiceData.invoice) !== undefined

  const renderC60Contextual = () =>
    totalGrossValue > 0 &&
    !invoiceFetching &&
    !deliveriesFetching && (
      <Features name="C60LeadWidget">
        <C60Lead
          countryCode={user?.country || ''}
          fullScreen={smallScreen}
          customerId={selectedCustomer?.customerId || ''}
          analytics={analytics}
          isMediumScreen={screenSize === 'md'}
          screenSize={screenSize}
          contextualC60Widget
          totalGrossValue={totalGrossValue}
          entryPoint={PageNames.INVOICE_DETAILS}
        />
        <Box mt={2} />
      </Features>
    )
  return (
    <Content style={{padding: smallScreen ? '8px' : 0}}>
      <Page
        data-test-id="invoice-details-page"
        boxed={false}
        variant={!largeScreen ? 'default' : 'withDetails'}
        {...(smallScreen ? {px: 0, py: 0} : {py: 0})}
      >
        <Box
          px={smallScreen ? 1 : 0}
          py={1}
          height={54}
          display="flex"
          justifyContent="space-between"
        >
          <TitleNav
            title={t('invoice.title')}
            onClick={goBack}
            aria-label="Back to all invoices button"
          />
          {invoiceData?.invoice && (
            <ExportDropdown
              responsiveStyle={smallScreen ? 'mobile' : 'desktop'}
              data-test-id="invoices-export-dropdown"
              title={t('invoice.export.buttontitle')}
              createJobId={handleExportClick}
              onClose={handleExportClose}
              options={[
                {
                  name: 'invoices-excel-export',
                  label: t('invoice.export.invoicesToExcel'),
                  onClick: () =>
                    exportInvoice(
                      invoiceData?.invoice,
                      accountParams,
                      user,
                      jobId,
                      ApiDownloadRequestFormats.xlsx,
                      analytics,
                      dispatch
                    )
                },
                {
                  name: 'invoices-csv-export',
                  label: t('invoice.export.invoicesToCsv'),
                  onClick: () =>
                    exportInvoice(
                      invoiceData?.invoice,
                      accountParams,
                      user,
                      jobId,
                      ApiDownloadRequestFormats.csv,
                      analytics,
                      dispatch
                    )
                },
                ...(canDownloadPdf
                  ? [
                      {
                        name: 'invoices-pdf-download',
                        label: t('invoiceDetail.download'),
                        onClick: () =>
                          downloadInvoicePdf(
                            invoiceData.invoice,
                            jobId || '',
                            analytics,
                            t,
                            getFeature,
                            dispatch
                          )
                      }
                    ]
                  : [])
              ]}
            />
          )}
        </Box>
        <Grid
          container
          spacing={2}
          style={{
            position: 'relative',
            marginTop: 0,
            marginBottom: 0,
            paddingBottom: '8px',
            minHeight: ResponsiveHeight,
            overflowY: 'auto'
          }}
        >
          <Grid
            item
            lg={8}
            xs={12}
            style={{
              maxHeight: largeScreen ? ResponsiveHeight : 'initial',
              paddingTop: 0,
              paddingBottom: 0,
              overflowY: 'auto'
            }}
          >
            <Paper
              elevation={4}
              aria-label="Invoice details"
              data-test-id="invoice-details-main-content"
              className={responsiveClasses.gridItem}
              style={{height: '100%'}}
              sx={{p: smallScreen ? 3 : 4}}
            >
              {invoiceData?.invoice ? (
                <>
                  <CustomHeader title={t('invoiceDetail.invoiceDetails')} />
                  <InvoiceHeader
                    invoice={invoiceData?.invoice}
                    t={t}
                    language={language}
                    isCustomerInvoiceNumber={isCustomerInvoiceNumber}
                    showState={true}
                    aside={false}
                  />

                  {invoiceData.invoice.type === 'invoice' && (
                    <Box mb={6} data-test-id="invoice-tax-regulation">
                      <Divider />
                      <Typography variant="caption" customColor="textPrimarySoft">
                        {t('invoiceDetail.taxRegulationCompliant')}
                      </Typography>
                    </Box>
                  )}

                  {smallScreen && (
                    <InvoiceItemsByMaterialList
                      data={tableData}
                      viewInvoiceItemDetails={(item) => setSelectedRow(item)}
                      currency={invoiceData?.invoice?.invoiceCurrency || ''}
                      loading={invoiceFetching || deliveriesFetching}
                    />
                  )}
                  {!smallScreen && invoiceData?.invoice.type !== InvoiceType.ManualBillig && (
                    <InvoiceItemsTable
                      invoice={invoiceData?.invoice}
                      isLoading={deliveriesFetching || invoiceFetching || tableData === undefined}
                      data={tableData}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                    />
                  )}
                  {!largeScreen && invoiceData?.invoice && (
                    <>
                      <CostSummary invoice={invoiceData?.invoice} />
                      {renderC60Contextual()}
                    </>
                  )}
                </>
              ) : invoiceFetching ? (
                <CustomSkeleton />
              ) : invoiceError ? (
                <Box>{t('error.server')}</Box>
              ) : (
                t('invoiceDetail.404')
              )}
            </Paper>
            {screenSize === 'md' && selectedRow ? (
              <Box
                position="absolute"
                right={0}
                top={0}
                height="100%"
                width={2 / 5}
                maxHeight={ResponsiveHeight}
              >
                <Slide
                  in={Boolean(showSlide)}
                  onExited={() => setSelectedRow(undefined)}
                  direction="left"
                  mountOnEnter
                  unmountOnExit
                  data-test-id={`slide-${selectedRow.invoiceId}`}
                  className={responsiveClasses.gridItem}
                >
                  <Paper
                    elevation={4}
                    color="white"
                    sx={{height: '100%', maxHeight: ResponsiveHeight, overflowY: 'auto', p: 4}}
                  >
                    <InvoiceItems
                      invoice={invoiceData?.invoice}
                      surchargeRelatedDeliveries={surchargeDeliveries}
                      selectedRow={selectedRow}
                      deliveries={deliveries}
                      analytics={analytics}
                      close={() => setShowSlide(false)}
                    />
                  </Paper>
                </Slide>
              </Box>
            ) : null}
          </Grid>
          {largeScreen && (
            <Grid
              item
              md={4}
              style={{
                maxHeight: ResponsiveHeight,
                paddingTop: 0,
                paddingBottom: 0,
                overflowY: 'auto'
              }}
              data-test-id="invoice-right-column"
            >
              <Paper
                elevation={4}
                color="white"
                className={responsiveClasses.gridItem}
                style={{height: '100%', overflowY: 'auto'}}
                sx={{p: largeScreen ? 4 : 2}}
              >
                {invoiceFetching || deliveriesFetching || surchargeDeliveriesFetching ? (
                  <CustomSkeleton />
                ) : selectedRow ? (
                  <InvoiceItems
                    invoice={invoiceData?.invoice}
                    surchargeRelatedDeliveries={surchargeDeliveries}
                    selectedRow={selectedRow}
                    deliveries={deliveries}
                    analytics={analytics}
                    close={() => setSelectedRow(undefined)}
                  />
                ) : deliveriesError || surchargeDeliveriesError ? (
                  <Box>{t('error.server')}</Box>
                ) : (
                  invoiceData?.invoice && <CostSummary invoice={invoiceData.invoice} />
                )}
                {renderC60Contextual()}
              </Paper>
            </Grid>
          )}
        </Grid>
      </Page>
      <Overlay
        data-test-id="sm-details-opened"
        isVisible={smallScreen && selectedRow}
        overlayClassName={responsiveClasses.overlay}
        contentClassName={classNames(responsiveClasses.overlayContent, responsiveClasses.gridItem)}
      >
        <InvoiceDeliveriesItems
          item={selectedRow}
          deliveries={deliveries}
          invoice={invoiceData?.invoice}
          analytics={analytics}
          close={() => setSelectedRow(undefined)}
        />
      </Overlay>
    </Content>
  )
}

const mapStateToProps = (
  state: AppState,
  {
    match: {
      params: {invoiceId}
    }
  }
) => ({
  invoiceId,
  invoice: selectInvoice(state, invoiceId)
})

const FINANCE_ROUTE = `${FinanceRoute}${InvoiceRoute}`

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      params: {invoiceId}
    }
  }
) => ({
  goBack: () => dispatch(push(FINANCE_ROUTE))
})

const ROUTE = `${InvoiceRoute}/:invoiceId`

export default withRouteRequiringPermission({
  path: ROUTE,
  hideInMainNav: true,
  routeProps: {exact: true},
  label: 'Invoice Details',
  permissions: [
    PermissionTypes.VIEW_INVOICES,
    PermissionTypes.VIEW_FINANCE,
    PermissionTypes.VIEW_ALL_DATA
  ]
})(connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail))
