/* eslint-disable react/jsx-wrap-multilines */
import {User} from '@hconnect/apiclient/src'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {
  dateRangeExceedsOneYear,
  dateRangeFormatter,
  ErrorText,
  getDateRangeInDays,
  lastThirtyDays,
  MOMENTJS_DATE_FORMAT,
  ResponsiveTable,
  Typography
} from '@hconnect/uikit'
import {Box, Paper, Slide} from '@mui/material'
import {findIndex, get} from 'lodash'
import moment, {MomentInput} from 'moment'
import queryString from 'query-string'
import React, {useCallback, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useLocation, withRouter} from 'react-router-dom'
import {v4 as uuidV4} from 'uuid'

import {
  EXPORT_FILENAME_DATEFORMAT,
  EXPORT_FILENAME_INVOICES,
  EXPORT_SHORTNAME_INVOICES
} from '../../../App.constants'
import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestTypes,
  SheetRequestType
} from '../../../AsyncJobQueue/AsyncJobQueue.types'
import {PageNames} from '../../../common/constants'
import {
  CustomerFeedbackBanner,
  CustomerFeedbackPanel
} from '../../../Molecules/CustomerFeedbackProgramme'
import {DocumentActionsBar} from '../../../Molecules/DocumentActionsBar'
import ExportDropdown from '../../../Molecules/ExportDropdown'
import {InformationBanner} from '../../../Molecules/InformationBanner/InformationBanner'
import {LoadMore} from '../../../Molecules/LoadMore'
import {OpenInNewTabButton} from '../../../Molecules/OpenInNewTab'
import {
  useResponsiveBreakpoints,
  useResponsiveGridStyles
} from '../../../Molecules/Responsive.utils'
import {ResultsForMessage} from '../../../Molecules/ResultsForMessage'
import {SMFilter} from '../../../Molecules/SMFilter/SMFilter'
import {useBranding} from '../../../Organisms/Branding'
import {selectSelectedCustomer} from '../../../Organisms/Customers'
import {Customer} from '../../../Organisms/Customers/Customers.types'
import {Entity, EntityPage, getDocumentFileName} from '../../../Organisms/Documents'
import {useFeaturesState} from '../../../Organisms/Features'
import {PayersStateType, selectPayers} from '../../../Organisms/Payers'
import {selectProject} from '../../../Organisms/Projects'
import ResultsPerPageSelector from '../../../Organisms/ResultsPerPage/ResultsPerPageSelector'
import {Site} from '../../../Organisms/SitesTypeahead'
import {PermissionTypes, usePermissions} from '../../../Permissions'
import {AppState} from '../../../Root.store'
import {shouldDataTableBeDisplayed} from '../../../util/action.helper'

import {InvoiceView, InvoiceViews, LookupSelectItem} from './Action.types'
import {InvoicesCardList, SelectedInvoicesAside} from './components'
import {ExcludedInvoicesAside} from './components/ExcludedInvoicesAside'
import Filters, {FilterBarErrorInvoice} from './Filters'
import {AccountParams, TotalAmountCriteria, useAccountParams} from './hooks'
import {useExcludedInvoices} from './hooks/useExcludedInvoices'
import {InvoiceColumns} from './Invoice.columns'
import {selectSurchargesOnlyFilter} from './Invoice.filters'
import {useStyles} from './Invoice.styles'
import {
  AsideToggleTypes,
  FinanceAnalytics,
  Invoice,
  InvoiceStatus,
  TotalAmountCalculationGroup
} from './Invoice.types'
import {
  excludedInvoiceGroupByReason,
  findInvoiceDocument,
  getCriteria,
  isIdLegacy,
  navigateToInvoiceDetails
} from './Invoice.utils'
import {InvoiceDetailsAside} from './InvoiceDetail/InvoiceDetails.aside'

const getShortName = (startDate: string, endDate: string) =>
  `${EXPORT_SHORTNAME_INVOICES}-${startDate.split('T')[0].split('-').join('')}-${endDate
    .split('T')[0]
    .split('-')
    .join('')}`

interface InvoiceComponentType {
  location: any
  fetchInvoices: (
    params: (AccountParams & {expand?: string}) | {siteId?: string},
    isPumpServiceIncluded?: boolean,
    analyticsId?: string
  ) => void
  items: Invoice[]
  view: InvoiceViews
  expandedItems: string[]
  setExpanded: (invoiceId: string, expanded: boolean) => void
  sortOrder: {key: string; asc: boolean}
  setSortKey: (sortKey: string) => void
  setDefaultDateFilter: ({startDate, endDate}: {startDate: any; endDate: any}) => void
  setDateFilter: ({startDate, endDate}: {startDate: any; endDate: any}) => void
  setOpenInvoicesFilter: ({status}: {status: InvoiceStatus[]}) => void
  isFetching: boolean
  error: any
  skip: number
  limit: number
  showLoadMore: boolean
  loadMore: () => void
  downloadDocument: (jobId: string, documentId: string, fileName: string) => void
  filters: any
  defaultFilters: any
  match: any
  history: any
  totalCount?: number
  bulkDownload: (args?: {
    sendEmail: boolean
    url: string
    fullName: string
    email: string
    country: string
    clientTimezone?: string
    analytics: any
    invoiceIds: string[]
    deliveryIds: string[]
    exportSettings: any
    surchargeItems: {invoiceId: string; deliveryIds: string[]}[]
  }) => void
  sheetRequest: ({criteria, type, format, name}: SheetRequestType) => void
  dateFilter: any
  setGlobalResultsPerPage: () => void
  toggles: AsideToggleTypes
}

// eslint-disable-next-line complexity
const InvoicePage: React.FC<InvoiceComponentType> = ({
  location,
  fetchInvoices,
  items,
  view,
  sortOrder: {key: sortedBy, asc: sortAscending},
  setSortKey,
  setDefaultDateFilter,
  setDateFilter,
  setOpenInvoicesFilter,
  isFetching,
  error,
  skip,
  limit,
  showLoadMore,
  loadMore,
  downloadDocument,
  filters,
  defaultFilters,
  history,
  bulkDownload,
  sheetRequest,
  dateFilter = {},
  setGlobalResultsPerPage,
  totalCount,
  toggles
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {getResolutionByNameAndConstraint, normalized} = useFeaturesState()
  const {screenSize, smallScreen, largeScreen} = useResponsiveBreakpoints()
  const branding = useBranding()
  const country = branding.country ?? ''
  const {getFeature} = useFeaturesState()
  const {hasPermission} = usePermissions()
  const isHavingServicesOrSurcharges = getFeature('SurchargeServiceOnlyFilter')
  const isCustomerInvoiceNumber = getFeature('CustomerInvoiceNumber')
  const isInvoiceStatusEnabled = getFeature('InvoiceStatus')
  const clearInvoicesEnabled = getFeature('ClearInvoices')
  const isExcludeCancelledInvoicesEnabled = getFeature('ExcludeCanceledInvoices')
  const isPumpServiceIncluded = getFeature('IncludePumpService')
  const getEmptyMessage = (loading: boolean) => (loading ? '' : t('noData'))
  const {classes} = useResponsiveGridStyles()

  const [selectedRow, setSelectedRow] = React.useState<Invoice | undefined>(undefined)
  const [selectedRows, setSelectedRows] = React.useState<Invoice[]>([])
  const [jobId, setJobId] = React.useState<string | undefined>(undefined)
  const [loadMoreClicked, setLoadMoreClicked] = React.useState<number>(0)
  const [isItemsEmpty, setIsItemsEmpty] = React.useState<boolean>(false)
  const [smFilterVisible, setSmFilterVisible] = React.useState<boolean>(false)
  const [site, setSite] = React.useState<Site | undefined>(undefined)
  const [showSlide, setShowSlide] = React.useState<boolean>(false)
  const [showExcludedInvoices, setShowExcludedInvoices] = React.useState<boolean>(false)
  const [initialRowsSelected, setInitialRowsSelected] = React.useState<Invoice[]>([])
  const [showCustomerPanel, setShowCustomerPanel] = React.useState<boolean>(false)
  const [rowsSelectable, setRowsSelectable] = React.useState<boolean>(true)
  const totalAmountCriteriaRef = useRef({} as TotalAmountCriteria)
  const {excludedInvoices} = useExcludedInvoices(selectedRows, totalAmountCriteriaRef.current)
  const lookupAnalyticsId = uuidV4()
  const {search} = useLocation()
  const queryParams = queryString.parse(search)

  const mainRef = React.useRef<HTMLDivElement>(null)

  const {classes: invoiceClasses} = useStyles()

  const resolveTableRowClassName = useCallback(
    (row: Invoice): string => {
      const excludedInvoice = excludedInvoices.find((i) => i.invoiceId === row.invoiceId)
      if (!excludedInvoice) {
        return ''
      }

      const excludedReasonGroup = excludedInvoiceGroupByReason(excludedInvoice.reason)

      return (
        (excludedReasonGroup && excludedReasonGroup === TotalAmountCalculationGroup.Excluded
          ? invoiceClasses.invoiceExcluded
          : invoiceClasses.invoiceIncluded) || ''
      )
    },
    [excludedInvoices, invoiceClasses]
  )

  useEffect(() => {
    setShowSlide(!!selectedRow || selectedRows.length > 0 || showCustomerPanel)
    setShowExcludedInvoices(false)
    setRowsSelectable(true)
  }, [selectedRow, selectedRows, showCustomerPanel])

  const handleRowClick = (row?: Invoice) => {
    if (row) {
      setSelectedRow(row)
      const {invoiceId, businessLine, invoiceCurrency, invoiceNetValue, materialItems} = row || {}

      const exposedItemIds = materialItems
        ? materialItems
            .filter(
              (materialItem) =>
                materialItem.links && materialItem.links.find((link) => link.rel === 'deliveries')
            )
            .map((filteredItem) =>
              filteredItem.links
                ? filteredItem.links.find((link) => link.rel === 'deliveries')?.href.split('/')[1]
                : 'N/A'
            )
        : []

      trackEvent('hubExpand', {
        product: 'hub',
        expandedItem: 'invoice',
        expandedItemId: invoiceId,
        expandedItemBusinessLine: businessLine,
        expandedInvoiceNetValue: invoiceNetValue,
        expandedInvoiceCurrency: invoiceCurrency,
        exposedItemIds,
        exposedItemsCount: exposedItemIds?.length || 0,
        expandedItemPosition: 1,
        userId: user?.user_id || '',
        userCountry: user?.country
      })
    } else {
      setSelectedRow(undefined)
    }
  }

  const handleDownloadInvoice = (invoice: Invoice, event?: React.MouseEvent) => {
    event && event.preventDefault()
    const invoicePdf = findInvoiceDocument(invoice)
    const invoiceNumber = isCustomerInvoiceNumber
      ? invoice.customerInvoiceNumber
      : invoice.invoiceNumber
    if (invoicePdf) {
      const fileName = getDocumentFileName(
        EntityPage.invoices,
        Entity.invoice,
        invoicePdf.media,
        t,
        {
          entityNumber: invoiceNumber
        }
      )
      const documentId = invoicePdf.href.split('/')[1]
      const jobId: string = uuidV4()
      trackEvent('hubDownloadSingle', {
        product: 'hub',
        jobId: jobId,
        downloadedItem: 'invoiceDetail',
        linkToDownloadedItem: invoicePdf.href,
        customerId: selectedCustomer?.customerId,
        customerName: selectedCustomer?.customerName,
        entryPoint: 'Invoice Details Aside-> Download PDF',
        downloadedItemPosition: 0,
        issuedDate: invoice.invoiceDate,
        userId: user?.user_id,
        userMail: user?.eMail,
        userCountry: user?.country,
        userRoles: user?.hasRoles,
        userIsInternal: user?.isInternal,
        downloadedItemBusinessLine: invoice.businessLine
      })
      downloadDocument(jobId, documentId, fileName)
    }
  }

  const bulkDownloadItems = (
    invoiceIds: string[],
    deliveryIds: string[],
    surchargeItems: {invoiceId: string; deliveryIds: string[]}[],
    combined: boolean
  ) => {
    const dateRangeInDays = getDateRangeInDays(startDate, endDate)
    bulkDownload({
      sendEmail,
      email: email || '',
      fullName: fullName || '',
      country: userCountry || '',
      url,
      clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      invoiceIds: invoiceIds,
      deliveryIds: deliveryIds,
      analytics: {
        eventName: 'hubBulkDownloadStart',
        product: 'hub',
        jobId: uuidV4(),
        numberOfDaysSelected: dateRangeInDays,
        initiationSource: 'hub',
        invoicesInView: items.length,
        totalItemsCount: totalCount,
        filterCustomer: customerId,
        filterShipTo: site?.siteId,
        filterToggleSurcharge: filters.includesServicesOrSurcharges,
        filterToggleOpenOnly: filters.status,
        filterDateRange: {startDate: startDate, endDate: endDate},
        exportCombined: combined
      },
      exportSettings: {
        combined: combined
      },
      surchargeItems: surchargeItems
    })
  }

  const handleExportClick = () => {
    const jobIdTemp = uuidV4()
    setJobId(jobIdTemp)
    trackEvent('hubExportClick', {
      product: 'hub',
      jobId: jobIdTemp,
      entryPoint: 'invoice',
      userId: user?.user_id || '',
      userCountry: user?.country,
      customerId,
      customerName: selectedCustomer?.customerName,
      filterShipTo: site?.siteId || '',
      filterToggleSurcharge: filters.includesServicesOrSurcharges,
      filterToggleOpenOnly: filters.status === 'open' ? true : false,
      filterDateRangeStart: startDate,
      filterDateRangeEnd: endDate,
      filterDateRangeDuration: getDateRangeInDays(startDate, endDate),
      lookUpQuery: isLookupActive ? lookupValue : '',
      lookUpCategory: isLookupActive ? lookup.entity : ''
    })
  }

  const handleExportClose = () => setJobId(undefined)

  // getting customerId from filters rather than taking it from the customer store
  const customerId = filters?.customer?.customerId
  const projectId = filters?.project?.projectId
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const {name: fullName, eMail: email, country: userCountry} = user || {}
  const sendEmail = true
  const {host} = (window as Window).location
  const url = `${RegExp(/localhost/).exec(host) ? 'http' : 'https'}://${host}/downloadRequests`

  const selectedCustomer = useSelector<AppState, Customer | undefined>((state: AppState) =>
    selectSelectedCustomer(state, customerId)
  )
  const {selectedPayer} = useSelector<AppState, PayersStateType>((state) => selectPayers(state))
  const includesServicesOrSurcharges = useSelector<AppState, boolean>((state) =>
    selectSurchargesOnlyFilter(state)
  )

  const selectedProject = useSelector<AppState, any>((state) => selectProject(state, projectId))

  const {lookup} = filters
  const isLookupActive = view === InvoiceView.LOOKUP

  const accountParams = useAccountParams()

  const invoicesIncludeManualBillings = getFeature('InvoicesIncludeManualBillings')

  useEffect(() => {
    if (!error && !isFetching && items && !(items.length > 0) && selectedCustomer) {
      filterBarErrors.push({key: 'empty', label: t('filterBar.errorEmpty')})
      setIsItemsEmpty(true)
    } else {
      setIsItemsEmpty(false)
    }
  }, [error, isLookupActive, isFetching, items, selectedCustomer])

  // initial setDefault Filters only once
  useEffect(() => {
    let [startDate, endDate] = lastThirtyDays(language)

    if (queryParams.startDate) startDate = moment(queryParams.startDate as MomentInput)
    if (queryParams.endDate) endDate = moment(queryParams.endDate as MomentInput)

    setDefaultDateFilter({
      startDate: startDate.format(MOMENTJS_DATE_FORMAT),
      endDate: endDate.format(MOMENTJS_DATE_FORMAT)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (queryParams.startDate && queryParams.endDate) {
      const startDateUrl = moment(queryParams.startDate as MomentInput)
      const endDateUrl = moment(queryParams.endDate as MomentInput)

      setDateFilter({
        startDate: startDateUrl.toISOString(),
        endDate: endDateUrl.toISOString()
      })
    }
  }, [queryParams.startDate, queryParams.endDate, setDateFilter])

  useEffect(() => {
    if (queryParams.status) {
      let filter = [] as InvoiceStatus[]
      if (Array.isArray(queryParams.status)) {
        filter = queryParams.status.map((x) => x as InvoiceStatus)
      } else {
        filter = queryParams.status.split(',').map((x) => x as InvoiceStatus)
      }

      setOpenInvoicesFilter({status: filter})
    }
  }, [queryParams.status, setOpenInvoicesFilter])

  useEffect(() => {
    if (queryParams.selectedInvoice && items) {
      const invoice = items.find((x) => x.invoiceId === queryParams.selectedInvoice)
      if (invoice) {
        setInitialRowsSelected([invoice])
      }
    }
  }, [queryParams.selectedInvoice, items])

  // only fetch when one of the following props changes
  const fetchConditions = [
    sortedBy,
    sortAscending,
    skip,
    limit,
    filters,
    defaultFilters,
    accountParams,
    site,
    clearInvoicesEnabled,
    isExcludeCancelledInvoicesEnabled
  ]
  useEffect(() => {
    if (!accountParams) {
      return
    }

    fetchInvoices(
      {
        ...accountParams,
        siteId: site?.siteId,
        expand: clearInvoicesEnabled ? 'onlinePaymentStatus' : undefined,
        ...(isExcludeCancelledInvoicesEnabled ? {excludeCancelledInvoices: true} : {})
      },
      isPumpServiceIncluded,
      lookupAnalyticsId
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, fetchConditions)

  const isDetailPageVisible = !location.pathname.replace(/\/$/, '').endsWith('/invoices')

  const startDate = get(dateFilter, 'startDate')
  const endDate = get(dateFilter, 'endDate')

  useEffect(() => {
    setSelectedRow(undefined)
  }, [
    selectedCustomer,
    selectedPayer,
    startDate,
    endDate,
    filters.surcharges,
    filters.status,
    setSelectedRow
  ])

  const analytics: FinanceAnalytics = {
    customerId,
    customerName: selectedCustomer?.customerName || '',
    userId: user?.user_id || '',
    userCountry: user?.country || '',
    userRoles: user?.hasRoles || [],
    userIsInternal: user?.isInternal || false,
    userMail: user?.eMail || '',
    startDate,
    endDate
  }
  const columnConfig = InvoiceColumns({
    t,
    downloadDocument,
    language,
    analytics,
    isCustomerInvoiceNumber,
    isInvoiceStatusEnabled
  })

  const isExportDisabled = () =>
    !(items && items.length) ||
    (dateFilter && dateRangeExceedsOneYear(dateFilter.startDate, dateFilter.endDate))

  const getExportFileName = (fileFormat) =>
    isLookupActive
      ? `${EXPORT_FILENAME_INVOICES}_${lookup.entity}_${lookup.value}.${fileFormat}`
      : `${EXPORT_FILENAME_INVOICES}_${moment(dateFilter.startDate).format(
          EXPORT_FILENAME_DATEFORMAT
        )}_-_${moment(dateFilter.endDate).format(EXPORT_FILENAME_DATEFORMAT)}.${fileFormat}`

  if (isDetailPageVisible) {
    return null
  }

  const filterBarErrors: FilterBarErrorInvoice[] = []
  if (
    dateRangeExceedsOneYear(get(dateFilter, 'startDate'), get(dateFilter, 'endDate')) &&
    selectedCustomer
  ) {
    filterBarErrors.push({
      key: 'dateRangeExceedsOneYear',
      label: t('filterBar.errorDateRangeExceedsOneYear')
    })
  }

  if (!error && isLookupActive && !isFetching) {
    if (items && !(items.length > 0)) {
      const getEntityLabel = () => {
        switch (get(lookup, 'entity', '')) {
          case LookupSelectItem.BOL:
            return 'ticket'

          case LookupSelectItem.TICKET:
            return 'ticket'

          case LookupSelectItem.INVOICE:
            return 'invoice'

          case LookupSelectItem.PO:
            return 'po'

          default:
            return 'invoice'
        }
      }
      filterBarErrors.push({
        key: 'noLookup',
        label: t('filterBar.noLookup', {
          lookupLabel: t(`invoice.lookup.${getEntityLabel()}No`),
          lookupValue: get(lookup, 'value', '')
        })
      })
    }
  }

  if (!selectedCustomer && !isFetching) {
    filterBarErrors.push({
      key: 'noSelectedCustomer',
      label: t('filterBar.noSelectedCustomer')
    })
  }

  const lookupEmpty = (): boolean => {
    if (
      findIndex(filterBarErrors, (error) => error.key === 'noLookup') !== -1 ||
      findIndex(filterBarErrors, (error) => error.key === 'dateRangeExceedsOneYear') !== -1
    ) {
      return true
    }
    return false
  }

  const displayLookupEntity = t(`invoice.lookup.${get(lookup, 'entity', '').toLowerCase()}No`)
  const lookupValue = get(lookup, 'value', '')

  const resultsForMessage = [
    selectedCustomer?.customerName || '',
    ...(selectedPayer &&
    selectedCustomer &&
    selectedCustomer.customerId !== selectedPayer.payerId &&
    selectedPayer.parentCustomerId === selectedCustomer.customerId
      ? [`${t('paidBy', {payerName: selectedPayer.payerName})}`]
      : []),
    !isLookupActive ? dateRangeFormatter(startDate, endDate, language) : null,
    isLookupActive ? `${displayLookupEntity} ${lookupValue}` : null,
    !isLookupActive ? selectedProject?.projectName : null,
    !isLookupActive ? site?.siteName : null,
    includesServicesOrSurcharges && !isLookupActive
      ? t('invoice.servicesOrSurcharges.filterLabel')
      : null
  ]
  const loadMoreFn = () => {
    setLoadMoreClicked(loadMoreClicked + 1)
    loadMore()
  }

  const excludeLegacyInvoiceFeature = getResolutionByNameAndConstraint(
    'ExcludeLegacySystemsFromPayments',
    normalized,
    'connectorId'
  )
  const canPayForInvoices =
    hasPermission(PermissionTypes.CHANGE_FINANCE) &&
    getFeature('PayAgainstInvoices') &&
    (getFeature('PayAgainstAccount') || getFeature('BofaPayments')) &&
    !isIdLegacy(selectedCustomer?.customerId, excludeLegacyInvoiceFeature) &&
    !isIdLegacy(selectedPayer?.payerId, excludeLegacyInvoiceFeature)

  const allInvoicesSelected = selectedRows?.length === items?.length && !showLoadMore
  const totalAmountCriteria: TotalAmountCriteria =
    !allInvoicesSelected || isLookupActive
      ? {
          type: 'invoices',
          invoices: selectedRows,
          includeManualBillings: invoicesIncludeManualBillings,
          ...(clearInvoicesEnabled ? {expand: 'onlinePaymentStatus'} : {})
        }
      : {
          type: 'filters',
          customerId,
          startDate,
          endDate,
          ...(site ? {siteId: site.siteId} : {}),
          ...(filters.project ? {projectId: filters.project.projectId} : {}),
          ...(filters.status && filters.status.status.length > 0
            ? {status: filters.status.status.join(',')}
            : {}),
          ...(clearInvoicesEnabled ? {expand: 'onlinePaymentStatus'} : {}),
          includeManualBillings: invoicesIncludeManualBillings
        }

  totalAmountCriteriaRef.current = totalAmountCriteria
  const rowHeight = 56

  return (
    <>
      <Paper
        elevation={8}
        aria-label="Invoice list"
        className={smallScreen ? '' : classes.gridItem}
        style={{
          zIndex: 100,
          paddingBottom: '2px',
          ...(!smallScreen ? {position: 'relative'} : {}),
          ...(!smallScreen ? {height: '100%'} : {})
        }}
      >
        {!smallScreen ? (
          <Filters
            errors={filterBarErrors}
            customerId={customerId}
            site={site}
            setSite={setSite}
            payerId={selectedPayer?.payerId}
            lookupAnalyticsId={lookupAnalyticsId}
            userId={user?.user_id || ''}
          />
        ) : (
          <SMFilter
            filters={[
              <Filters
                key="all-filters"
                errors={filterBarErrors}
                customerId={customerId}
                site={site}
                setSite={setSite}
                lookupAnalyticsId={lookupAnalyticsId}
                userId={user?.user_id || ''}
                xs
              />
            ]}
            isVisible={smFilterVisible}
            setIsVisible={setSmFilterVisible}
            numberOfItems={items?.length}
            apply={() => setSmFilterVisible(false)}
          />
        )}

        {error && (
          <ErrorText
            label={t(error.translationKey)}
            showRetry={error?.callback}
            onRetry={error.callback}
          />
        )}

        {!error && !isItemsEmpty && selectedCustomer && !smallScreen && (
          <DocumentActionsBar>
            <ResultsPerPageSelector
              data-test-id="results-per-page-selector"
              items={items}
              isFetching={isFetching}
              onClick={setGlobalResultsPerPage}
            />
            <Box display="flex">
              {canPayForInvoices && branding.paymentGuideLink ? (
                <Box display="flex" paddingRight={3}>
                  <OpenInNewTabButton
                    data-test-id="payment-guide-button"
                    title={t('invoice.paymentGuideLink')}
                    link={branding.paymentGuideLink}
                    trackingEventName="hubPaymentGuideClick"
                    trackingEventParams={{
                      product: 'hub',
                      analyticsId: uuidV4(),
                      userId: user?.user_id || '',
                      userCountry: user?.country,
                      customerId,
                      customerName: selectedCustomer?.customerName
                    }}
                  />
                </Box>
              ) : null}
              <ExportDropdown
                data-test-id="invoices-export-dropdown"
                title={t('invoice.export.buttontitle')}
                disabled={isExportDisabled()}
                createJobId={handleExportClick}
                onClose={handleExportClose}
                options={[
                  {
                    name: 'invoices-excel-export',
                    label: t('invoice.export.invoicesToExcel'),
                    onClick: () => {
                      const dateRangeInDays = getDateRangeInDays(startDate, endDate)
                      sheetRequest({
                        jobId,
                        criteria: getCriteria({
                          isLookupActive,
                          startDate,
                          endDate,
                          accountParams,
                          site,
                          sortedBy,
                          sortAscending,
                          isHavingServicesOrSurcharges,
                          filters,
                          items,
                          type: 'invoiceList',
                          isExcludeCancelledInvoicesEnabled
                        }),
                        type: ApiDownloadRequestTypes.invoiceDeliveryExport,
                        format: ApiDownloadRequestFormats.xlsx,
                        name: getExportFileName(ApiDownloadRequestFormats.xlsx),
                        shortName: getShortName(startDate, endDate),
                        fileType: 'Excel',
                        dateRangeInDays,
                        startTimeStamp: startDate,
                        endTimeStamp: endDate,
                        email: email || '',
                        sendEmail,
                        fullName: fullName || '',
                        url,
                        country: userCountry || country,
                        clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        analytics: {
                          jobId,
                          numberOfDaysSelected: dateRangeInDays,
                          initiationSource: 'hub'
                        }
                      })
                      trackEvent('hubExportStart', {
                        product: 'hub',
                        jobId,
                        fileFormat: 'excel',
                        entryPoint: 'order',
                        userId: user?.user_id || '',
                        userCountry: user?.country,
                        customerId,
                        customerName: selectedCustomer?.customerName
                      })
                    }
                  },
                  {
                    name: 'invoices-csv-export',
                    label: t('invoice.export.invoicesToCsv'),
                    onClick: () => {
                      const dateRangeInDays = getDateRangeInDays(startDate, endDate)
                      sheetRequest({
                        jobId,
                        criteria: getCriteria({
                          isLookupActive,
                          startDate,
                          endDate,
                          accountParams,
                          site,
                          sortedBy,
                          sortAscending,
                          isHavingServicesOrSurcharges,
                          filters,
                          items,
                          type: 'invoiceList',
                          isExcludeCancelledInvoicesEnabled
                        }),
                        type: ApiDownloadRequestTypes.invoiceDeliveryExport,
                        format: ApiDownloadRequestFormats.csv,
                        name: getExportFileName(ApiDownloadRequestFormats.csv),
                        shortName: getShortName(startDate, endDate),
                        fileType: 'CSV',
                        dateRangeInDays,
                        startTimeStamp: startDate,
                        endTimeStamp: endDate,
                        email: email || '',
                        sendEmail,
                        fullName: fullName || '',
                        url,
                        country: userCountry || country,
                        clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        analytics: {
                          jobId,
                          numberOfDaysSelected: dateRangeInDays,
                          initiationSource: 'hub'
                        }
                      })
                      trackEvent('hubExportStart', {
                        product: 'hub',
                        jobId,
                        fileFormat: 'csv',
                        entryPoint: 'invoice',
                        userId: user?.user_id || '',
                        userCountry: user?.country,
                        customerId,
                        customerName: selectedCustomer?.customerName
                      })
                    }
                  }
                ]}
              />
            </Box>
          </DocumentActionsBar>
        )}
        {!error &&
          !lookupEmpty() &&
          shouldDataTableBeDisplayed(items, isLookupActive, isFetching, customerId) &&
          selectedCustomer && (
            <>
              <ResultsForMessage messageList={resultsForMessage} />
              {!smallScreen ? (
                <Box px={2} className={classes.gridItem}>
                  <InformationBanner
                    message={t('checkboxInformationBanner.invoices')}
                    pageName="invoices"
                    tableHasData={!isFetching && items?.length > 0}
                  />
                  <ResponsiveTable
                    data-test-id="invoice-list"
                    columns={columnConfig}
                    rows={items}
                    loading={isFetching && skip === 0}
                    onSort={(e, sortKey) => {
                      setSortKey(sortKey)
                    }}
                    isRowSelectable={() => true}
                    sortedBy={sortedBy}
                    sortingOrder={sortAscending ? 'asc' : 'desc'}
                    selectionEnabled={true}
                    keyField="invoiceId"
                    onRowClick={handleRowClick}
                    rowDetailsAvailable
                    rowDetailsClosed={selectedRow === undefined}
                    getSelectedRows={(selectedRows) => setSelectedRows([...selectedRows])}
                    emptyMessage={
                      <Typography variant="caption">{getEmptyMessage(isFetching)}</Typography>
                    }
                    rowHeight={rowHeight}
                    preselectedRows={{rows: initialRowsSelected, initial: true}}
                    promoBanner={
                      <CustomerFeedbackBanner
                        setKnowMore={(val: boolean) => setShowCustomerPanel(val)}
                        entryPoint={PageNames.FINANCE_PAGE}
                      />
                    }
                    allowSelectRowsNotSelectedYet={rowsSelectable}
                    rowOptions={{resolveClassName: resolveTableRowClassName}}
                  />
                </Box>
              ) : (
                <Box mx={2}>
                  <InvoicesCardList
                    rows={items}
                    loading={isFetching && skip === 0}
                    keyField="invoiceId"
                    fullScreen={smallScreen}
                    analytics={analytics}
                    customerId={customerId}
                    countryCode={userCountry || ''}
                    screenSize={screenSize}
                    toggles={toggles}
                    download={(invoice: Invoice) => handleDownloadInvoice(invoice)}
                    viewInvoiceDetailsPage={(event, row: Invoice) =>
                      navigateToInvoiceDetails(
                        row.invoiceId,
                        row.customerInvoiceNumber,
                        selectedCustomer?.customerId || '',
                        history,
                        accountParams,
                        event
                      )
                    }
                  />
                </Box>
              )}
            </>
          )}
        {showLoadMore && (
          <LoadMore isFetching={isFetching} skip={skip} onLoadMoreClick={loadMoreFn} onLight />
        )}
      </Paper>
      <Box position="absolute" width={1 / 3} ref={mainRef} height="1px" top={0} right={0} />
      {!smallScreen && (selectedRow || selectedRows?.length > 0 || showCustomerPanel) && (
        <Box
          position="absolute"
          right={largeScreen ? 20 : 0}
          top={0}
          height="100%"
          width={1 / 3}
          zIndex={largeScreen ? 99 : 101}
          py={0}
        >
          <Slide
            in={Boolean(showSlide)}
            onExited={() => {
              setSelectedRow(undefined)
              setSelectedRows([])
              setShowCustomerPanel(false)
              setShowExcludedInvoices(false)
            }}
            direction={largeScreen ? 'right' : 'left'}
            mountOnEnter
            unmountOnExit
            container={mainRef?.current}
            className={classes.gridItem}
          >
            <Paper
              elevation={16}
              color="white"
              style={{
                height: '100%',
                overflowY: 'auto',
                paddingLeft: largeScreen ? 16 : 0
              }}
            >
              {showCustomerPanel ? (
                <CustomerFeedbackPanel
                  close={() => setShowSlide(false)}
                  entryPoint={PageNames.FINANCE_PAGE}
                />
              ) : selectedRows.length > 0 ? (
                showExcludedInvoices ? (
                  <ExcludedInvoicesAside
                    totalAmountCriteria={totalAmountCriteria}
                    close={() => setShowExcludedInvoices(false)}
                    t={t}
                    invoices={selectedRows}
                    language={language}
                  />
                ) : (
                  <SelectedInvoicesAside
                    invoices={selectedRows}
                    language={language}
                    t={t}
                    close={() => setShowSlide(false)}
                    bulkDownload={bulkDownloadItems}
                    totalAmountCriteria={totalAmountCriteria}
                    allInvoicesSelected={allInvoicesSelected}
                    paymentsEnabled={canPayForInvoices}
                    payerId={selectedPayer?.payerId}
                    payerNumber={selectedPayer?.payerNumber || ''}
                    customerId={selectedCustomer?.customerId || ''}
                    countryCode={selectedPayer?.countryId || ''}
                    setRowsSelectable={setRowsSelectable}
                    showExcludedInvoices={() => setShowExcludedInvoices(true)}
                  />
                )
              ) : selectedRow ? (
                <InvoiceDetailsAside
                  invoice={selectedRow}
                  download={(event?: React.MouseEvent) => handleDownloadInvoice(selectedRow, event)}
                  expand={(event?: React.MouseEvent<HTMLButtonElement>) =>
                    navigateToInvoiceDetails(
                      selectedRow.invoiceId,
                      selectedRow.customerInvoiceNumber,
                      selectedCustomer?.customerId || '',
                      history,
                      accountParams,
                      event
                    )
                  }
                  close={() => setShowSlide(false)}
                  customerId={selectedCustomer?.customerId || ''}
                  payerId={selectedPayer?.payerId}
                  analytics={analytics}
                  toggles={toggles}
                  isMobile={smallScreen}
                  aside
                />
              ) : null}
            </Paper>
          </Slide>
        </Box>
      )}
    </>
  )
}

export default withRouter(InvoicePage)
